import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Importing arrow icons

function Displayedit() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(false); // Track screen size for mobile
  const swiperRef = useRef(null);

  useEffect(() => {
    // Fetching the data
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backendprogram.passiontrials.co.za/api/getperson/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchData();

    // Checking if the screen size is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile if the width is 768px or less
    };

    // Set the initial check and add a resize listener
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);

  const handleNextSlide = () => {
    if (swiperRef.current) {
      console.log("Next Slide clicked"); // Debug: Make sure the click is registered
      swiperRef.current.scrollBy({ left: swiperRef.current.offsetWidth, behavior: "smooth" });
    } else {
      console.error("swiperRef is null"); // Debug: Make sure swiperRef is correctly attached
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      console.log("Previous Slide clicked"); // Debug: Make sure the click is registered
      swiperRef.current.scrollBy({ left: -swiperRef.current.offsetWidth, behavior: "smooth" });
    } else {
      console.error("swiperRef is null"); // Debug: Make sure swiperRef is correctly attached
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="album__images" style={{ position: "relative" }}>
      {/* Slider container */}
      <div
        className="slider-container"
        ref={swiperRef}
        style={{ overflowX: "auto", display: "flex", cursor: "grab" }}
      >
        <ul className="swiper-wrapper image-swiper" style={{ display: "flex", transition: "0.3s ease-in-out" }}>
          {data.additionalImages &&
            data.additionalImages.map((image, index) => (
              <li
                className="swiper-slide image-swiper__item"
                key={index}
                style={{ minWidth: "300px", marginRight: "20px", boxSizing: "border-box" }} // Adjusted size
              >
                <memory-image className="memory-image">
                  <div className="memory-image__container">
                    <img
                      className="memory-image__front"
                      style={{ width: "100%", height: "auto", objectFit: "contain" }} // Maintaining aspect ratio
                      data-sizes="auto"
                      data-srcset={`https://backendprogram.passiontrials.co.za/uploads/${image}`}
                      src={`https://backendprogram.passiontrials.co.za/uploads/${image}`}
                      alt={`Additional ${index + 1}`}
                    />
                  </div>
                </memory-image>
              </li>
            ))}
        </ul>
      </div>

      {/* Conditionally render buttons based on screen size */}
      {!isMobile && (
        <div className="slider-controls" style={styles.buttonContainer}>
          <button
            onClick={handlePrevSlide}
            className="prev-button"
            style={styles.arrowButton}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </button>
          <button
            onClick={handleNextSlide}
            className="next-button"
            style={styles.arrowButton}
          >
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </button>
        </div>
      )}
    </div>
  );
}

// Styles
const styles = {
  buttonContainer: {
    position: "absolute",
    top: "10px", // Align the buttons at the top
    right: "10px", // Align the buttons to the right
    display: "flex", // Buttons will be displayed next to each other
    gap: "10px", // Space between the buttons
    pointerEvents: "none", // Allow interaction with images underneath
  },
  arrowButton: {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    pointerEvents: "all", // Enable button clicks
  },
};

export default Displayedit;
