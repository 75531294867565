import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Input from './components/Input';
import Display from './components/Display';
import Displayedit from './components/Displayedit';
import Home from './components/Home';
import '../src/assets/css/v-3bb755598353d071de57c461c6b59a3c/index.css'
import logo from '../src/components/icebolethu.png'
function App() {
  return (
    <> 
	 <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/input" element={<Input/>} />
      <Route path="/display/:id" element={<Display/>} />
      <Route path="/displayedit/:id" element={<Displayedit/>} />
      </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
