import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import logo from "../components/icebolethu.png";
import left from "../components/left.png";
import right from "../components/right.png";

import html2canvas from "html2canvas"; // to capture the images
import jsPDF from "jspdf";



function Display() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const swiperRef = useRef(null); // Reference for the image swiper container
  const contentRef = useRef(null); // Reference for the content to be converted to PDF

  const formatRelationship = (relationship) => {
    if (!relationship) {
      return ""; // Return an empty string if there's no relationship data
    }

    // Check if the relationship is already an array, if not, treat it as a string and split it
    const relationshipArray = Array.isArray(relationship)
      ? relationship
      : relationship.split(","); // Assuming it's a comma-separated string if not an array

    if (relationshipArray.length === 1) {
      return relationshipArray[0]; // Return the single relationship directly
    }

    // Handle multiple relationships
    const lastItem = relationshipArray.pop(); // Get the last item
    return `${relationshipArray.join(", ")} and ${lastItem}`; // Join remaining with commas, add "and" before last item
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backendprogram.passiontrials.co.za/api/getperson/${id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchData();
  }, [id]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Funeral Details",
          text: "Here are the details of the funeral.",
          url: window.location.href,
        });
        console.log("Data was shared successfully");
      } catch (err) {
        console.error("Error sharing the data", err);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  // Function to format the date to '{formatDate(data.dod)}'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Adding ordinal suffix to the day
    const ordinalSuffix = (n) => {
      if (n > 3 && n < 21) return "th"; // Exceptions for 11th to 13th
      switch (n % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
  };

  
  const generatePDF = async () => {
    const doc = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 paper
    const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width
    const pageHeight = doc.internal.pageSize.getHeight(); // Get the page height
  
    // Set background color to #fbee9f
    doc.setFillColor("#fbee9f");
    doc.rect(0, 0, pageWidth, pageHeight, "F"); // Draw a filled rectangle to cover the entire page
  
    // Set the font to Times (which is a formal font)
    doc.setFont("Times", "normal");
  
    // Load and convert the logo to Base64 (ensure it's PNG for transparency)
    const logoBase64 = await loadImageToBase64(logo);
  
    // Add the logo to the PDF (PNG ensures transparency handling)
    if (logoBase64) {
      doc.addImage(logoBase64, "PNG", 10, 10, 50, 20); // Add logo at the top-left corner
    }
  
    // Add the date of birth and date of death at the top right corner
    const dobAndDodText = `DOB: ${formatDate(data.dob)}\nDOD: ${formatDate(data.dod)}`;
    doc.setFontSize(12);
    const textX = pageWidth - 10; // Position it at 10mm from the right edge
    doc.text(dobAndDodText, textX, 20, { align: 'right' }); // Use 'right' alignment
  
    // Helper function to center text
    const centerText = (text, yPosition, fontSize = 18) => {
      doc.setFontSize(fontSize);
      const textWidth = doc.getTextWidth(text);
      const xPosition = (pageWidth - textWidth) / 2; // Calculate X position to center text
      doc.text(text, xPosition, yPosition);
    };
  
    // Add a title below the logo
    centerText("Funeral Program", 40, 24);
  
    // Add the name of the deceased
    centerText(` ${data.name}`, 50, 23);
  
    // Add additional information like the funeral address, program, etc.
    centerText(`Funeral Date: ${formatDate(data.funeralDate)}`, 80, 12);
    centerText(`Funeral Address: ${data.funeralAddress}`, 90, 12);
    centerText(`Service Venue: ${data.serviceVenue}`, 100, 12);
    centerText(`Cemetery: ${data.cemetery}`, 110, 12);
  
    // Try to add the image from the database if available
    if (data.image) {
      try {
        // Convert the image URL to Base64
        const imageUrl = `https://backendprogram.passiontrials.co.za/uploads/${data.image}`;
        const imageBase64 = await loadImageToBase64(imageUrl);
  
        if (imageBase64) {
          const imgWidth = 50; // Set image width
          const imgHeight = 50; // Set image height
          doc.addImage(imageBase64, "JPEG", (pageWidth - imgWidth) / 2, 120, imgWidth, imgHeight); // Center the image
        }
      } catch (error) {
        console.error("Error loading image from the database:", error);
      }
    }
  
    // Add obituary
    if (data.obituary) {
      doc.setFontSize(14);
      doc.text("Obituary", 10, 180); // Add heading for obituary
      doc.setFontSize(12);
      doc.text(doc.splitTextToSize(data.obituary, pageWidth - 20), 10, 190); // Wrap the text to fit in the page width
    }
  
    // Add program
    if (data.program) {
      doc.setFontSize(14);
      doc.text("Program", 10, 220); // Add heading for program
      doc.setFontSize(12);
      doc.text(doc.splitTextToSize(data.program, pageWidth - 20), 10, 230); // Wrap the text to fit in the page width
    }
  
    // Save the PDF
    doc.save(`${data.name}_Funeral_Program.pdf`);
  };
  
  // Helper function to convert image URLs to Base64
  const loadImageToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Allow cross-origin images to be loaded
      img.src = imageUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png"); // Make sure you use PNG for transparency
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };


  


  

  if (!data) {
    return <div>Loading...</div>;
  }

  // Scroll left and right functions
  const scrollLeft = () => {
    swiperRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    swiperRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };
  return (
    <body class="default-page" >
     <style>{`
     /* Example CSS for PDF output */
@media print {
  .container {
    width: 100%;
    padding: 120px;
  }
  .swiper-container {
    overflow: hidden;
    width: 100%;
  }
}
`}</style>

      <div toggle-open-text="open" toggle-close-text="close">
        <header class="site-header">
          <div class="site-header__mob-top">
            <div class="site-header__logo">
              <div id="swup-skip-links">
                <ul class="skip-links">
                  <li>
                    <button>
                      <a class="button skip-links__link" href="#swup-main">
                        Skip to main content<span class="button__bg"></span>
                      </a>
                    </button>
                  </li>
                </ul>
              </div>

              <a class="site-header__logo" href="../../index.htm">
                <span class="audio-only">Marie Curie Memory Wall Home</span>
                <img width={150} src={logo} alt="Logo" />
              </a>
            </div>

            <button
              class="nav-toggle button-reset hide-no-js hide-l"
              onClick={toggleMenu}
            >
              <span class="nav-toggle__icon">
                <span class="nav-toggle__icon-dash"></span>
                <span class="nav-toggle__icon-dash"></span>
                <span class="nav-toggle__icon-dash"></span>
              </span>

              <span class="nav-toggle__text">
                <span class="audio-only">Toggle</span>
                <span class="nav-toggle__open">Menu</span>
                <span class="nav-toggle__close" aria-hidden="true">
                  Close
                </span>
              </span>
            </button>
          </div>

          <div className={`site-header__mob-bottom ${menuOpen ? "open" : ""}`}>
            <nav class="site-header__nav-container" id="swup-header-nav">
              <ul
                class="site-header__nav site-nav"
                aria-label="Page navigation"
              >
                <li class="site-nav__item">
                  <a
                    class="site-nav__link button button--text"
                    href="../index.htm"
                  >
                    Explore
                  </a>
                </li>

                <li class="site-nav__item">
                  <a class="site-nav__link button button--text" href="#album">
                    Album
                  </a>
                </li>

                <li class="site-nav__item">
                  <a
                    class="site-nav__link button button--text"
                    href="#memories"
                  >
                    Program
                  </a>
                </li>

                <li class="site-nav__item">
                  <a class="site-nav__link button button--text" href="#eulogy">
                    Obituary
                  </a>
                </li>
              </ul>

              <div class="site-header__featured-cta">
                <a
                  class="site-header__search-link hide-s"
                  href="../../search/index.htm"
                >
                  <span class="audio-only">Search tributes</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="none"
                    viewbox="0 0 21 21"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      stroke="currentColor"
                      fill="none"
                      d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"
                    ></path>
                  </svg>
                </a>

                  <a class="button" href="../../add-a-loved-one/index.htm">
                    Add a loved one<span class="button__bg"></span>
                  </a>
              </div>

              <div class="site-header__edit-cta hide-l">
                  <a
                    class="button"
                    href="https://www.icebolethugroup.co.za/funeral-cover/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Visit icebolethu <span class="button__bg"></span>
                  </a>
              </div>

              <ul class="site-header__nav site-nav site-nav--footer hide-l">
                <li class="site-nav__item">
                  <a
                    class="footer-nav__link button button--text color-sandstone"
                    href="../../contact/index.htm"
                  >
                    Contact
                  </a>
                </li>
                

                
              </ul>

              <div class="site-header__additional-nav hide-l">
                <ul class="footer-nav" aria-label="Additional page options">
                  <li class="footer-nav__item">
                    <div class="share-links">
                      <p class="share-links__toggle button button--text">
                        <span class="audio-only">Share navigation</span>

                        <svg
                          class="icon icon--share"
                          width="15"
                          height="17"
                          viewbox="0 0 15 17"
                          xmlns="http://www.w3.org/2000/svg"
                          focusable="false"
                        >
                          <g fill-rule="evenodd">
                            <circle cx="12.5" cy="2.5" r="2.5"></circle>
                            <circle cx="12.5" cy="14.5" r="2.5"></circle>
                            <circle cx="2.5" cy="8.5" r="2.5"></circle>
                            <path d="M12.75 1.63l.53.85L2.25 9.37l-.53-.85z"></path>
                            <path d="M2 8.85L2.53 8l11.03 6.89-.53.85z"></path>
                          </g>
                        </svg>
                      </p>

                      <div class="share-links__container">
                        <ul class="share-links__list">
                          <li class="share-links__item">
                            <div>
                              <a
                                class="share-links__link"
                                href="mailto:?subject=Marie+Curie+Memory+Cloud&body=www.memorycloud.org.uk"
                              >
                                <span class="audio-only">Share via email</span>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewbox="0 0 20 14"
                                  width="20"
                                  height="14"
                                  class="icon icon--email"
                                  focusable="false"
                                >
                                  <path d="M.6 0h18.8c.3 0 .6.3.6.6v12.8c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0zm10.7 6.5l6.4-5.4H2.2l6.4 5.4L10 7.7l1.3-1.2zM15.1 5l-2.4 2 6.1 5.1V1.9L15.1 5zM7.3 7L1.2 1.9v10.3L7.3 7zm7 2.9l-2.6-2.2-1.3 1.1-2.1-1-6 5h15.5l-3.5-2.9z"></path>
                                </svg>
                              </a>
                            </div>
                          </li>

                          <li class="share-links__item">
                            <div>
                              <a
                                class="share-links__link"
                                href="../../login.php?u=www.memorycloud.org.uk"
                              >
                                <span class="audio-only">
                                  Share via facebook
                                </span>

                                <svg
                                  width="8"
                                  height="16"
                                  viewbox="0 0 8 16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon--facebook"
                                  focusable="false"
                                >
                                  <path
                                    d="M5.193 16V8.702H7.55l.353-2.845H5.193V4.041c0-.823.22-1.384 1.357-1.384L8 2.656V.112A18.694 18.694 0 0 0 5.887 0C3.796 0 2.365 1.325 2.365 3.76v2.097H0v2.845h2.365V16h2.828z"
                                    fill-rule="nonzero"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </li>

                          <li class="share-links__item">
                            <div>
                              <a
                                class="share-links__link"
                                href="https://twitter.com/intent/tweet/?text=Marie+Curie+Memory+Cloud&url=www.memorycloud.org.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span class="audio-only">
                                  Share via twitter
                                </span>

                                <svg
                                  width="18"
                                  height="14"
                                  viewbox="0 0 18 14"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon--twitter"
                                  focusable="false"
                                >
                                  <path
                                    d="M5.55 13.944c6.66 0 10.301-5.364 10.301-10.016 0-.152-.003-.304-.01-.455a7.255 7.255 0 0 0 1.806-1.822 7.39 7.39 0 0 1-2.08.554A3.552 3.552 0 0 0 17.16.258c-.7.403-1.474.696-2.299.854A3.666 3.666 0 0 0 12.218 0c-2 0-3.621 1.576-3.621 3.52 0 .276.032.545.094.802A10.38 10.38 0 0 1 1.228.645a3.434 3.434 0 0 0-.49 1.769c0 1.221.64 2.3 1.611 2.93a3.672 3.672 0 0 1-1.64-.44v.044c0 1.705 1.248 3.129 2.904 3.451a3.729 3.729 0 0 1-1.635.06c.461 1.4 1.798 2.417 3.382 2.445a7.398 7.398 0 0 1-4.496 1.507c-.292 0-.58-.016-.864-.049a10.463 10.463 0 0 0 5.55 1.582"
                                    fill-rule="nonzero"
                                  ></path>
                                </svg>
                              </a>
                            </div>
                          </li>

                          <li class="share-links__item">
                            <div>
                              <a
                                class="share-links__link"
                                href="https://api.whatsapp.com/send?text=www.memorycloud.org.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span class="audio-only">
                                  Share via WhatsApp
                                </span>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewbox="0 0 24 24"
                                  class="icon icon--whatsapp"
                                  focusable="false"
                                >
                                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                                </svg>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>

            <div class="site-header__search hide-l">
              <div class="search-form">
                <form
                  action="/explore/andrew-andy-william-dalgarno/"
                  autocomplete="off"
                  enctype="multipart/form-data"
                  method="post"
                >
                  <input
                    name="__RequestVerificationToken"
                    type="hidden"
                    value="EqzNp49ckG7CGpKy-DxO5KRX155LvSV4evHbQN0-hW2YaFsrnfcs9ksNsRGjP9K60_ODsbPbXqINnOTsQZOfy-YYA041_vBCDKwmuLOl6_s1"
                  />
                  <div class="form__validation-summary"></div>
                  <form-field class="form-field form-field--text form-field--search">
                    <label class="form-field__label" for="Name">
                      <span class="form-field__label-text">
                        <span class="audio-only-s">Search Memory Cloud</span>
                        <span class="hide-l" aria-hidden="true">
                          Search...
                        </span>
                      </span>
                    </label>

                    <input
                      class="form-field__input"
                      id="Name"
                      name="Name"
                      type="search"
                      value=""
                      placeholder="Search..."
                    />
                  </form-field>
                  <button
                    class="button-reset search-form__submit"
                    type="submit"
                  >
                    <span class="audio-only">Submit</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="none"
                      viewbox="0 0 21 21"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        stroke="currentColor"
                        fill="none"
                        d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"
                      ></path>
                    </svg>
                  </button>
                  <span class="form__working-message">Searching</span>
                  <input
                    name="ufprt"
                    type="hidden"
                    value="99BA1FB4633C8E2A85643503905EE1507E48987606C6F5A49B0BECAFD7FE443E90817DEAFC001118455B3E9A477B864D72A0201E01B6C978E24232B11C019327BB18A34ED5BC0C62504346793864F591B96C9847AFCA0745A4E1C8AC0793E3EF2676672DBE4008ADB2CA1DBB4861AF6EF4350072642B7B1FF1B69025EAD9A3F94464827E98F95FE5E49F6EA47689E55B"
                  />
                </form>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div>
        <div class="site-container" data-scroll="">
   
        <main id="swup-main">
        <div ref={contentRef}>
            <header class="tribute-header container">
              <h1 class="tribute-header__heading h1">{data.name} </h1>

              <p class="tribute-header__date date-text color-sandstone">
                <time datetime="1961-11-30T00:00:00.0000000Z">
                  {formatDate(data.dob)}{" "}
                </time>
                <span> - </span>

                <time datetime="2019-12-12T00:00:00.0000000Z">
                  {formatDate(data.dod)}
                </time>
              </p>

              <p className="tribute-header__sub-heading h4 color-sandstone">
                {formatRelationship(data.relationship)}
              </p>

              <div class="rich-text rich-text--drop-cap tribute-header__text">
                {/* <p>Fun loving and a hard working husband, dad, brother &amp; grandad.  Always on hand to help family, friends, neighbours &amp; strangers if need be.  Always the first to arrive and last to leave a party.  Will be missed by all who knew him.</p> */}

                <picture>
                  <source
                    media="(max-width: 1024px)"
                    sizes="1px"
                    srcset="../../assets/images/blank.gif 1w"
                  />
                  <source
                    media="(min-width: 1025px)"
                    sizes="166px"
                    srcset="../../assets/images/petals/petal-4-1.png 332w?mode=pad&amp;width=332 332w,
						../../assets/images/petals/petal-4.png 166wal-4.png?mode=pad&amp;width=166 166w"
                  />
                  <img
                    class="tribute-header__petal tribute-header__petal--summary"
                    src="../../assets/images/petals/petal-4.png?mode=pad&amp;width=166"
                    alt=""
                  />
                </picture>
              </div>

              <div class="tribute-header__img">
                {data.image && (
                  <img
                    src={`https://backendprogram.passiontrials.co.za/uploads/${data.image}`}
                    alt="Deceased"
                  />
                )}
              </div>

              <img
                class="tribute-header__letter"
                src="../../assets/letters/A.svg"
                alt=""
              />

              <picture>
                <source
                  media="(max-width: 1024px)"
                  sizes="1px"
                  srcset="../../assets/images/blank.gif 1w"
                />
                <source
                  media="(min-width: 1025px)"
                  sizes="300px"
                  srcset="../../assets/images/petals/petal-3-1.png 360w?mode=pad&amp;width=360 360w,
				../../assets/images/petals/petal-3.png 180w-3.png?mode=pad&amp;width=180 180w"
                />
                <img
                  class="tribute-header__petal tribute-header__petal--header"
                  src="../../assets/images/petals/petal-3.png?mode=pad&amp;width=180"
                  alt=""
                />
              </picture>
              <picture>
                <source
                  media="(max-width: 1024px)"
                  sizes="1px"
                  srcset="../../assets/images/blank.gif 1w"
                />
                <source
                  media="(min-width: 1025px)"
                  sizes="360px"
                  srcset="../../assets/images/petals/petal-1-1.png 720w?mode=pad&amp;width=720 720w,
				../../assets/images/petals/petal-1.png 360w-1.png?mode=pad&amp;width=360 360w"
                />
                <img
                  class="tribute-header__petal tribute-header__petal--side"
                  src="../../assets/images/petals/petal-1.png?mode=pad&amp;width=360"
                  alt=""
                />
              </picture>
            </header>
            </div>
            <div class="album container">
              <span class="anchor-offset" ></span>

              <div class="album__content">
                
                <div class="rich-text album__text">
                  

                  <p>
                    <strong>Date of Funeral:</strong>{" "}
                    {formatDate(data.funeralDate)}
                  </p>
                  <p>
                    <strong>Address of Funeral:</strong> {data.funeralAddress}
                  </p>
                  <p>
                    <strong>Service Venue:</strong> {data.serviceVenue}
                  </p>
                  <p>
                    <strong>Cemetery:</strong> {data.cemetery}
                  </p>
                  <p>
                    <strong>Contact Information:</strong> {data.contact}
                  </p>
                  <button>
                    <a class="button" onClick={handleShare}>
                      Share this page<span class="button__bg"></span>
                    </a>
                  </button>
          <a class="button" onClick={generatePDF}>
            Download PDF <span class="button__bg"></span>
          </a>
                </div>
              </div>
			  <div class="album__content">
                <h2 class="album__heading h2">Album</h2>
                <div class="rich-text album__text">
                  <p id="album">
                    A gallery of the fondest memories captured throughout the
                    years. Relive those cherished moments in time or add your
                    special memories of {data.name} to the gallery.
                  </p>
                </div>
              </div>
              <div className="album__images" >
                <div className="swiper-container" ref={swiperRef}>
                  <ul className="swiper-wrapper image-swiper">
                    {data.additionalImages &&
                      data.additionalImages.map((image, index) => (
                        <li
                          className="swiper-slide image-swiper__item"
                          key={index}
                        >
                          <div className="memory-image">
                            <div className="memory-image__container">
                              <img
                                className="memory-image__front"
                                src={`https://backendprogram.passiontrials.co.za/uploads/${image}`}
                                alt={`Additional ${index + 1}`}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="scroll-buttons">
                  <button className="scroll-button left" onClick={scrollLeft}>
                    <img src={left} alt="Scroll Left" />
                  </button>
                  <button className="scroll-button right" onClick={scrollRight}>
                    <img src={right} alt="Scroll Right" />
                  </button>
                </div>
              </div>
            </div>

            <div class="memories">
              <div class="memories__container container">
                <span class="anchor-offset"></span>

                <h2 class="memories__heading h2" id="memories">Program </h2>

                <ul class="memories__list">
                  <li class="memory memories__item">
                    {/* <h3 class="h3">Deep heat</h3> */}

                    <div class="rich-text rich-text--drop-cap">
                      <p>
                        <strong>Program:</strong> {data.program}
                      </p>
                    </div>

                    {/* <div class="memory__info">
                      <p class="memory__date date-text color-sandstone">
                        <time datetime="2020-10-27T19:44:00.2100000Z">
                          27th Oct 2020
                        </time>{" "}
                        - Jason
                      </p>
                    </div> */}
                  </li>
                </ul>
              </div>

              <img
                class="memories__letter"
                src="../../assets/letters/M.svg"
                alt=""
              />

              <picture>
                <source
                  media="(max-width: 1024px)"
                  sizes="1px"
                  srcset="../../assets/images/blank.gif 1w"
                />
                <source
                  media="(min-width: 1025px)"
                  sizes="215px"
                  srcset="../../assets/images/petals/petal-5-1.png 430w?mode=pad&amp;width=430 430w,
					../../assets/images/petals/petal-5.png 215wl-5.png?mode=pad&amp;width=215 215w"
                />
                <img
                  class="memories__petal memories__petal--heading"
                  src="../../assets/images/petals/petal-5.png?mode=pad&amp;width=215"
                  alt=""
                />
              </picture>
              <picture>
                <source
                  media="(max-width: 1024px)"
                  sizes="1px"
                  srcset="../../assets/images/blank.gif 1w"
                />
                <source
                  media="(min-width: 1025px)"
                  sizes="331px"
                  srcset="../../assets/images/petals/petal-6-1.png 662w?mode=pad&amp;width=662 662w,
					../../assets/images/petals/petal-6.png 331wl-6.png?mode=pad&amp;width=331 331w"
                />
                <img
                  class="memories__petal memories__petal--left"
                  src="../../assets/images/petals/petal-6.png?mode=pad&amp;width=331"
                  alt=""
                />
              </picture>
              <picture>
                <source
                  media="(max-width: 1024px)"
                  sizes="1px"
                  srcset="../../assets/images/blank.gif 1w"
                />
                <source
                  media="(min-width: 1025px)"
                  sizes="264px"
                  srcset="../../assets/images/petals/petal-2-1.png 528w?mode=pad&amp;width=528 528w,
					../../assets/images/petals/petal-2.png 264wl-2.png?mode=pad&amp;width=264 264w"
                />
                <img
                  class="memories__petal memories__petal--bottom"
                  src="../../assets/images/petals/petal-2.png?mode=pad&amp;width=264"
                  alt=""
                />
              </picture>
            </div>

            <div class="eulogy container">
              <span class="anchor-offset" ></span>

              <div class="eulogy__content" id="eulogy">
                <h2 class="eulogy__heading h1">Obituarys</h2>
                <p class="eulogy__summary h3"> {data.obituary}</p>
                <p class="eulogy__date date-text color-sandstone">
                  <time datetime="1961-11-30T00:00:00.0000000Z">
                    {formatDate(data.dob)}
                  </time>
                  <span> - </span>

                  <time datetime="2019-12-12T00:00:00.0000000Z">
                    {formatDate(data.dod)}
                  </time>
                </p>
              </div>
              <img
                class="eulogy__letter"
                src="../../assets/letters/E.svg"
                alt=""
              />
            </div>
            
          </main>

<footer class="tribute-footer">
              <div class="tribute-footer__content container">
                <div class="tribute-footer__heading">
                  Do you want to join Icebolethu?
                </div>
                  <a class="button" href="../../add-a-loved-one/index.htm">
                    Add a loved ones<span class="button__bg"></span>
                  </a>
              </div>
              <div class="tribute-footer__bg">
                <img src="../../assets/images/share-petal.png" alt="" />
              </div>
            </footer>
          <footer class="site-footer">
            <nav class="site-footer__left"
              aria-label="Marie Curie support links" >
              <ul class="footer-nav hide-s">
                <li class="footer-nav__item">
                  <button>
                    <a class="button"
                      href="https://www.icebolethugroup.co.za/funeral-cover/"
                      rel="noopener noreferrer"
                      target="_blank" >
                      Visit Icebolethu <span class="button__bg"></span>
                    </a>
                  </button>
                </li>
              </ul>
            </nav>
            <nav
              class="site-footer__right" aria-label="Additional page options">
              <ul class="footer-nav hide-s" id="swup-footer-nav">
                <li class="footer-nav__item"></li>
                <li class="footer-nav__item">
                  <a class="button button--text" href="../../contact/index.htm">
                    Contact
                  </a>
                </li>
                <li class="footer-nav__item">
                  <div class="share-links">
                    <p class="share-links__toggle button button--text">
                      <span class="audio-only">Share navigation</span>

                      <svg
                        class="icon icon--share"
                        width="15"
                        height="17"
                        viewbox="0 0 15 17"
                        xmlns="http://www.w3.org/2000/svg"
                        focusable="false"
                      >
                        <g fill-rule="evenodd">
                          <circle cx="12.5" cy="2.5" r="2.5"></circle>
                          <circle cx="12.5" cy="14.5" r="2.5"></circle>
                          <circle cx="2.5" cy="8.5" r="2.5"></circle>
                          <path d="M12.75 1.63l.53.85L2.25 9.37l-.53-.85z"></path>
                          <path d="M2 8.85L2.53 8l11.03 6.89-.53.85z"></path>
                        </g>
                      </svg>
                    </p>

                    <div class="share-links__container">
                      <ul class="share-links__list">
                        <li class="share-links__item">
                          <div>
                            <a
                              class="share-links__link"
                              href="mailto:?subject=Marie+Curie+Memory+Cloud&body=www.memorycloud.org.uk"
                            >
                              <span class="audio-only">Share via email</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewbox="0 0 20 14"
                                width="20"
                                height="14"
                                class="icon icon--email"
                                focusable="false"
                              >
                                <path d="M.6 0h18.8c.3 0 .6.3.6.6v12.8c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0zm10.7 6.5l6.4-5.4H2.2l6.4 5.4L10 7.7l1.3-1.2zM15.1 5l-2.4 2 6.1 5.1V1.9L15.1 5zM7.3 7L1.2 1.9v10.3L7.3 7zm7 2.9l-2.6-2.2-1.3 1.1-2.1-1-6 5h15.5l-3.5-2.9z"></path>
                              </svg>
                            </a>
                          </div>
                        </li>

                        <li class="share-links__item">
                          <div>
                            <a
                              class="share-links__link"
                              href="../../login.php?u=www.memorycloud.org.uk"
                            >
                              <span class="audio-only">Share via facebook</span>

                              <svg
                                width="8"
                                height="16"
                                viewbox="0 0 8 16"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon--facebook"
                                focusable="false"
                              >
                                <path
                                  d="M5.193 16V8.702H7.55l.353-2.845H5.193V4.041c0-.823.22-1.384 1.357-1.384L8 2.656V.112A18.694 18.694 0 0 0 5.887 0C3.796 0 2.365 1.325 2.365 3.76v2.097H0v2.845h2.365V16h2.828z"
                                  fill-rule="nonzero"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </li>

                        <li class="share-links__item">
                          <div>
                            <a
                              class="share-links__link"
                              href="https://twitter.com/intent/tweet/?text=Marie+Curie+Memory+Cloud&url=www.memorycloud.org.uk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span class="audio-only">Share via twitter</span>

                              <svg
                                width="18"
                                height="14"
                                viewbox="0 0 18 14"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon--twitter"
                                focusable="false"
                              >
                                <path
                                  d="M5.55 13.944c6.66 0 10.301-5.364 10.301-10.016 0-.152-.003-.304-.01-.455a7.255 7.255 0 0 0 1.806-1.822 7.39 7.39 0 0 1-2.08.554A3.552 3.552 0 0 0 17.16.258c-.7.403-1.474.696-2.299.854A3.666 3.666 0 0 0 12.218 0c-2 0-3.621 1.576-3.621 3.52 0 .276.032.545.094.802A10.38 10.38 0 0 1 1.228.645a3.434 3.434 0 0 0-.49 1.769c0 1.221.64 2.3 1.611 2.93a3.672 3.672 0 0 1-1.64-.44v.044c0 1.705 1.248 3.129 2.904 3.451a3.729 3.729 0 0 1-1.635.06c.461 1.4 1.798 2.417 3.382 2.445a7.398 7.398 0 0 1-4.496 1.507c-.292 0-.58-.016-.864-.049a10.463 10.463 0 0 0 5.55 1.582"
                                  fill-rule="nonzero"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </li>

                        <li class="share-links__item">
                          <div>
                            <a
                              class="share-links__link"
                              href="https://api.whatsapp.com/send?text=www.memorycloud.org.uk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span class="audio-only">Share via WhatsApp</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewbox="0 0 24 24"
                                class="icon icon--whatsapp"
                                focusable="false"
                              >
                                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                              </svg>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="site-footer__audio-toggle">
                <button class="audio-toggle">
                  <span class="audio-only">Toggle sounds</span>
                  <span class="audio-toggle__circle">
                    <svg
                      class="audio-toggle__svg"
                      width="14"
                      height="15"
                      viewbox="0 0 14 15"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <g class="audio-toggle__bar">
                        <rect x="9" y="0" width="2" height="15"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="12" y="12" width="2" height="3"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="3" y="9" width="2" height="6"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="0" y="3" width="2" height="12"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <polygon points="6 6 8 6 8 15 6 15"></polygon>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </nav>
          </footer>
        </div>
      </div>

      <div class="page-border hide-s">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <style>
        {`
  .album__images {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-container {
    overflow-x: scroll;
    display: flex;
    scroll-behavior: smooth;
    min-width: 120%; /* Ensures the container is wider than its parent */
  }

  .swiper-wrapper {
    display: flex;
  }

  .image-swiper__item {
    min-width: 300px; /* Each image has a minimum width */
    margin-right: 20px; /* Spacing between images */
  }

  .scroll-buttons {
    position: absolute;
    top: -70px; /* Position the buttons near the top */
    right: 10px; /* Align the buttons to the right */
    display: flex;
    gap: 10px; /* Space between the buttons */
    z-index: 10;
  }

  .scroll-button {
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scroll-button img {
    width: 44px; /* Set the desired size for the arrow images */
    height: 44px;
  }
  /* Hide the scroll buttons on mobile devices (max-width: 768px) */
  @media (max-width: 768px) {
    .scroll-buttons {
      display: none;
    }
  }
`}
      </style>
    </body>
  );
}

export default Display;
