import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Input.css"; // Assuming you have an Input.css file for styles
import logo from "./icebolethu.png";
import upload from "./icons8-photo-gallery-100.png";
import singleupload from "./icons8-image-100.png";

function Input() {
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    dod: "",
    funeralDate: "",
    funeralAddress: "",
    serviceVenue: "",
    cemetery: "",
    contact: "",
    program: "",
    obituary: "",
    relationship: [], // Initialized as an empty array
  });
  const [mainFile, setMainFile] = useState(null);
  const [mainFilePreview, setMainFilePreview] = useState(null); // For main file preview
  const [additionalFilePreviews, setAdditionalFilePreviews] = useState([]); // For additional files preview

  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);

  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button


  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files : value,
    }));
  };

  const handleMainFileChange = (e) => {
    const file = e.target.files[0];
    setMainFile(file);
    setMainFilePreview(URL.createObjectURL(file)); // Generate preview URL
  };

  const handleAdditionalFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setAdditionalFiles(files);
    setAdditionalFilePreviews(files.map((file) => URL.createObjectURL(file))); // Generate preview URLs
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button and show the loading state

    // Format relationships
    const relationships = formData.relationship || [];
    let formattedRelationships = relationships.join(", ");

    // Initialize FormData object
    const formDataToSubmit = new FormData();

    // Append relationship data to FormData
    formDataToSubmit.append("relationship", formattedRelationships);

    for (const key in formData) {
      if (key !== "relationship") {
        formDataToSubmit.append(key, formData[key]);
      }
    }

    if (mainFile) {
      formDataToSubmit.append("image", mainFile);
    }
    additionalFiles.forEach((file) => {
      formDataToSubmit.append("additionalImages", file);
    });

    // Log FormData to check if 'relationship' is included
    for (var pair of formDataToSubmit.entries()) {
      console.log(pair[0] + ", " + pair[1]); // Log all form data to verify the relationship
    }

    // Send data to backend
    try {
      const response = await axios.post(
        "https://backendprogram.passiontrials.co.za/api/addperson",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const personId = response.data.id;
      navigate(`/display/${personId}`);
    } catch (error) {
      console.error("There was an error adding the person!", error);
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission is complete
    }
  };

  const handleRelationshipChange = (e) => {
    const { value, checked } = e.target;

    setFormData((prevData) => {
      const relationships = prevData.relationship || []; // Make sure it’s initialized as an array

      if (checked) {
        // Add relationship if checked
        return { ...prevData, relationship: [...relationships, value] };
      } else {
        // Remove relationship if unchecked
        return {
          ...prevData,
          relationship: relationships.filter((item) => item !== value),
        };
      }
    });
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const isSelected = (value) => formData.relationship.includes(value);

  return (
    <body class="default-page" aria-live="polite">
      <site-header toggle-open-text="open" toggle-close-text="close">
        <header class="site-header">
          <div class="site-header__mob-top">
            <div class="site-header__logo">
              <skip-links id="swup-skip-links">
                <ul class="skip-links">
                  <li>
                    <mc-button>
                      <a class="button skip-links__link" href="#swup-main">
                        Skip to main content<span class="button__bg"></span>
                      </a>
                    </mc-button>
                  </li>
                </ul>
              </skip-links>

              <a class="site-header__logo" href="../index.htm">
                <span class="audio-only">Marie Curie Memory Wall Home</span>
                <img src={logo} width="40%" />
              </a>
            </div>

            <button
              class="nav-toggle button-reset hide-no-js hide-l"
              onClick={toggleMenu}
            >
              <span class="nav-toggle__icon">
                <span class="nav-toggle__icon-dash"></span>
                <span class="nav-toggle__icon-dash"></span>
                <span class="nav-toggle__icon-dash"></span>
              </span>

              <span class="nav-toggle__text">
                <span class="audio-only">Toggle</span>
                <span class="nav-toggle__open">Menu</span>
                <span class="nav-toggle__close" aria-hidden="true">
                  Close
                </span>
              </span>
            </button>
          </div>

          <div className={`site-header__mob-bottom ${menuOpen ? "open" : ""}`}>
            <nav class="site-header__nav-container" id="swup-header-nav">
              <div class="site-header__featured-cta">
                <a class="button button--text" href="../learn-more/index.htm">
                  Learn More
                </a>
                <a
                  class="site-header__search-link hide-s"
                  href="../search/index.htm"
                >
                  <span class="audio-only">Search tributes</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="none"
                    viewbox="0 0 21 21"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      stroke="currentColor"
                      fill="none"
                      d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"
                    ></path>
                  </svg>
                </a>

                <p class="site-header__flavour-text hide-l">
                  A place to store and share the memories of loved ones we've
                  lost
                </p>
              </div>

              <div class="site-header__additional-nav hide-l">
                <ul class="footer-nav" aria-label="Additional page options">
                  <li class="footer-nav__item">
                    <share-links class="share-links">
                      <p class="share-links__toggle button button--text">
                        <span class="audio-only">Share navigation</span>

                        <svg
                          class="icon icon--share"
                          width="15"
                          height="17"
                          viewbox="0 0 15 17"
                          xmlns="http://www.w3.org/2000/svg"
                          focusable="false"
                        >
                          <g fill-rule="evenodd">
                            <circle cx="12.5" cy="2.5" r="2.5"></circle>
                            <circle cx="12.5" cy="14.5" r="2.5"></circle>
                            <circle cx="2.5" cy="8.5" r="2.5"></circle>
                            <path d="M12.75 1.63l.53.85L2.25 9.37l-.53-.85z"></path>
                            <path d="M2 8.85L2.53 8l11.03 6.89-.53.85z"></path>
                          </g>
                        </svg>
                      </p>

                      <div class="share-links__container">
                        <ul class="share-links__list">
                          <li class="share-links__item">
                            <share-link>
                              <a
                                class="share-links__link"
                                href="mailto:?subject=Marie+Curie+Memory+Cloud&body=www.memorycloud.org.uk"
                              >
                                <span class="audio-only">Share via email</span>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewbox="0 0 20 14"
                                  width="20"
                                  height="14"
                                  class="icon icon--email"
                                  focusable="false"
                                >
                                  <path d="M.6 0h18.8c.3 0 .6.3.6.6v12.8c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0zm10.7 6.5l6.4-5.4H2.2l6.4 5.4L10 7.7l1.3-1.2zM15.1 5l-2.4 2 6.1 5.1V1.9L15.1 5zM7.3 7L1.2 1.9v10.3L7.3 7zm7 2.9l-2.6-2.2-1.3 1.1-2.1-1-6 5h15.5l-3.5-2.9z"></path>
                                </svg>
                              </a>
                            </share-link>
                          </li>

                          <li class="share-links__item">
                            <share-link>
                              <a
                                class="share-links__link"
                                href="../login.php?u=www.memorycloud.org.uk"
                              >
                                <span class="audio-only">
                                  Share via facebook
                                </span>

                                <svg
                                  width="8"
                                  height="16"
                                  viewbox="0 0 8 16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon--facebook"
                                  focusable="false"
                                >
                                  <path
                                    d="M5.193 16V8.702H7.55l.353-2.845H5.193V4.041c0-.823.22-1.384 1.357-1.384L8 2.656V.112A18.694 18.694 0 0 0 5.887 0C3.796 0 2.365 1.325 2.365 3.76v2.097H0v2.845h2.365V16h2.828z"
                                    fill-rule="nonzero"
                                  ></path>
                                </svg>
                              </a>
                            </share-link>
                          </li>

                          <li class="share-links__item">
                            <share-link>
                              <a
                                class="share-links__link"
                                href="https://twitter.com/intent/tweet/?text=Marie+Curie+Memory+Cloud&url=www.memorycloud.org.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span class="audio-only">
                                  Share via twitter
                                </span>

                                <svg
                                  width="18"
                                  height="14"
                                  viewbox="0 0 18 14"
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon--twitter"
                                  focusable="false"
                                >
                                  <path
                                    d="M5.55 13.944c6.66 0 10.301-5.364 10.301-10.016 0-.152-.003-.304-.01-.455a7.255 7.255 0 0 0 1.806-1.822 7.39 7.39 0 0 1-2.08.554A3.552 3.552 0 0 0 17.16.258c-.7.403-1.474.696-2.299.854A3.666 3.666 0 0 0 12.218 0c-2 0-3.621 1.576-3.621 3.52 0 .276.032.545.094.802A10.38 10.38 0 0 1 1.228.645a3.434 3.434 0 0 0-.49 1.769c0 1.221.64 2.3 1.611 2.93a3.672 3.672 0 0 1-1.64-.44v.044c0 1.705 1.248 3.129 2.904 3.451a3.729 3.729 0 0 1-1.635.06c.461 1.4 1.798 2.417 3.382 2.445a7.398 7.398 0 0 1-4.496 1.507c-.292 0-.58-.016-.864-.049a10.463 10.463 0 0 0 5.55 1.582"
                                    fill-rule="nonzero"
                                  ></path>
                                </svg>
                              </a>
                            </share-link>
                          </li>

                          <li class="share-links__item">
                            <share-link>
                              <a
                                class="share-links__link"
                                href="https://api.whatsapp.com/send?text=www.memorycloud.org.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span class="audio-only">
                                  Share via WhatsApp
                                </span>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewbox="0 0 24 24"
                                  class="icon icon--whatsapp"
                                  focusable="false"
                                >
                                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                                </svg>
                              </a>
                            </share-link>
                          </li>
                        </ul>
                      </div>
                    </share-links>
                  </li>
                </ul>
              </div>
            </nav>

            <div class="site-header__search hide-l">
              <div class="search-form">
                <form
                  action="/add-a-loved-one/"
                  autocomplete="off"
                  enctype="multipart/form-data"
                  method="post"
                >
                  <input
                    name="__RequestVerificationToken"
                    type="hidden"
                    value="s9QqjVbKCKCpOEtbbtIluXu-t0DpDFsUu1ccpoQYDgCenyX-s8QmBnXkkgvmHVK7C9tEPQvW6GEbjfdWO43nbahxM3GpW1_1WgxYq9uTH3A1"
                  />{" "}
                  <div class="form__validation-summary"></div>
                  <form-field class="form-field form-field--text form-field--search">
                    <label class="form-field__label" for="Name">
                      <span class="form-field__label-text">
                        <span class="audio-only-s">Search Memory Cloud</span>
                        <span class="hide-l" aria-hidden="true">
                          Search...
                        </span>
                      </span>
                    </label>

                    <input
                      class="form-field__input"
                      id="Name"
                      name="Name"
                      type="search"
                      value=""
                      placeholder="Search..."
                    />
                  </form-field>
                  <button
                    class="button-reset search-form__submit"
                    type="submit"
                  >
                    <span class="audio-only">Submit</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      fill="none"
                      viewbox="0 0 21 21"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        stroke="currentColor"
                        fill="none"
                        d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"
                      ></path>
                    </svg>
                  </button>
                  <span class="form__working-message">Searching</span>
                  <input
                    name="ufprt"
                    type="hidden"
                    value="D32E2F3437CA5351C30E9D0FBF272642D562066AE2CDA0B9FDA22A093B3CD584A7672A81676CEA5C7FD965A38DCD0158E4710DD61E5514B35683D931B52EE99DCDC8BFA2F9C42B0548257DCF5741CBCBBF2038C6DFC93500BCB48AD66EAB70FB56DA4D343A62EBA99386D65E6BB00B48AE605D863BEAC075977627191DA7DCEC2A9C48F3FCA8D9CBBC205AABED09470D"
                  />
                </form>
              </div>
            </div>
          </div>
        </header>
      </site-header>

      <smooth-scroll>
        <div class="site-container" data-scroll="">
          <main id="swup-main">
            <div class="container add-tribute-form">
              <add-tribute-form
                class="form"
                action="/umbraco/api/addtributeformajax/submit"
                method="POST"
              >
                <form onSubmit={handleSubmit}>
                  <div class="form__validation-summary"></div>
                  <form-page class="form-page form__page">
                    <fieldset>
                      {currentStep === 1 && (
                        <>
                          <legend class="form-page__heading form-page__heading--center h3">
                            What was your loved one's name?
                          </legend>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Name of the deceased:
                              </label>
                            </div>
                          </form-field>
                          <div className="relative mb-6">
                            {/* <label className="floating-label text-gray-600">Select Relationship(s):</label> */}
                            <div>
                              {[
                                "Father",
                                "Mother",
                                "Sister",
                                "Brother",
                                "Grandmother",
                                "Grandfather",
                              ].map((relationship) => (
                                <label
                                  key={relationship}
                                  style={{
                                    marginRight: "10px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    backgroundColor: isSelected(relationship)
                                      ? "#d3f9d8"
                                      : "transparent",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    value={relationship}
                                    checked={isSelected(relationship)}
                                    onChange={handleRelationshipChange}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {relationship}
                                </label>
                              ))}
                            </div>
                          </div>
                          <div class="form-page__controls">
		<div class="form-page__controls-left">
		</div>

		<div class="form-page__controls-right">
    <mc-button>
		<button class="button js-next" type="button" onClick={handleNext}>
			Next <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>
	</div>
                
                        </>
                      )}
                      {currentStep === 2 && (
                        <>
                          <legend class="form-page__heading form-page__heading--center h3">
                            Select Dates
                          </legend>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="date"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Date of Birth (Sunrise):
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="date"
                                name="dod"
                                value={formData.dod}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Date of Death (Sunset):
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="date"
                                name="funeralDate"
                                value={formData.funeralDate}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Date of Funeral:
                              </label>
                            </div>
                          </form-field>
                          <div class="form-page__controls">
		<div class="form-page__controls-left">
	<mc-button>
		<button class="button js-previous" type="button"  onClick={handlePrevious}>
			Back <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>

		<div class="form-page__controls-right">
	<mc-button>
		<button class="button js-next" type="button" onClick={handleNext}>
			Next <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>
	</div>

                          
                          
                        </>
                      )}
                      {currentStep === 3 && (
                        <>
                          <legend class="form-page__heading form-page__heading--center h3">
                            Funeral Details
                          </legend>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="text"
                                name="funeralAddress"
                                value={formData.funeralAddress}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Address of Funeral:
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="text"
                                name="serviceVenue"
                                value={formData.serviceVenue}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Service Venue:
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="text"
                                name="cemetery"
                                value={formData.cemetery}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Cemetery:
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <input
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                type="text"
                                name="contact"
                                value={formData.contact}
                                onChange={handleChange}
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Contact Information:
                              </label>
                            </div>
                          </form-field>
                          <div class="form-page__controls">
		<div class="form-page__controls-left">
	<mc-button>
		<button class="button js-previous" type="button"  onClick={handlePrevious}>
			Back <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>

		<div class="form-page__controls-right">
	<mc-button>
		<button class="button js-next" type="button" onClick={handleNext}>
			Next <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>
	</div>
                        </>
                      )}
                      {currentStep === 4 && (
                        <>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <textarea
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                name="program"
                                value={formData.program}
                                onChange={handleChange}
                                maxLength="1000"
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Program (up to 1000 words):
                              </label>
                            </div>
                          </form-field>
                          <form-field class=" form-field--text js-first-name-input">
                            <div class="relative mb-6">
                              <textarea
                                placeholder=" "
                                class="floating-input w-full border-b-2 border-gray-300 focus:border-gray-700 focus:outline-none bg-transparent py-2"
                                name="obituary"
                                value={formData.obituary}
                                onChange={handleChange}
                                maxLength="1500"
                                required
                              />
                              <label
                                for="first-name"
                                class="floating-label text-gray-600"
                              >
                                Obituary (up to 1500 words):
                              </label>
                            </div>
                          </form-field>
                          <div class="form-page__controls">
		<div class="form-page__controls-left">
	<mc-button>
		<button class="button js-previous" type="button"  onClick={handlePrevious}>
			Back <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>

		<div class="form-page__controls-right">
	<mc-button>
		<button class="button js-next" type="button" onClick={handleNext}>
			Next <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>
	</div>
                        </>
                      )}
                       {currentStep === 5 && (
                <>
                  <div className="file-upload-container">
                    {/* Main file upload */}
                    <div className="file-upload">
                      <label htmlFor="file" className="labelFile">
                        <span>
                          <img src={singleupload} alt="single upload" />
                        </span>
                        <p style={{fontSize:'15px'}}>Drag and drop your file here or click to select a file!</p>
                      </label>
                      <input
                        className="input"
                        name="text"
                        id="file"
                        key={fileInputKey}
                        type="file"
                        accept="image/*"
                        onChange={handleMainFileChange}
                      />

                      {/* Display main file preview */}
                      {mainFilePreview && (
                        <div className="image-preview">
                          <img src={mainFilePreview} alt="Main file preview" />
                        </div>
                      )}
                    </div>

                    {/* Additional files upload */}
                    <div className="file-upload">
                      <label htmlFor="additionalFiles" className="labelFile">
                        <span>
                          <img src={upload} alt="upload" />
                        </span>
                        <p style={{fontSize:'15px'}}>Drag and drop your additional files here or click to select files!</p>
                      </label>
                      <input
                        className="input"
                        name="text"
                        id="additionalFiles"
                        key={fileInputKey}
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleAdditionalFilesChange}
                      />

                      {/* Display additional files preview */}
                      {additionalFilePreviews.length > 0 && (
                        <div className="additional-image-previews">
                          {additionalFilePreviews.map((preview, index) => (
                            <img key={index} src={preview} alt={`Additional file ${index + 1}`} />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                                <div class="form-page__controls">
		<div class="form-page__controls-left">
    <mc-button>
		<button class="button js-previous" type="button"  onClick={handlePrevious}>
			Back <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>

		<div class="form-page__controls-right">
    <mc-button>
		<button class="button js-next"  type="submit" disabled={isSubmitting}>
    {isSubmitting ? "Creating..." : "Create"}
    <span class="button__bg"></span>
		<span style={{position: "absolute", display: "block", width: "109px", height: "109px", borderradius: "50%", background: "", opacity: "0.4", transform: "translate(-50%, -50%) scale(2)", transformorigin: "center center",
       transition: "transform 0.25s ease-in-out",
        pointerevents: "none", zindex: "-1", top: "36px",
        left: "41.9531px"}}></span></button>
	</mc-button>
		</div>
	</div>
                  
                </>
              )}
                     
                    </fieldset>
                  </form-page>
                </form>
              </add-tribute-form>
            </div>
          </main>

          <footer class="site-footer">
            <nav
              class="site-footer__left"
              aria-label="Marie Curie support links"
            >
              <ul class="footer-nav hide-s">
                <li class="footer-nav__item">
                  <mc-button>
                    <a
                      class="button"
                      href="https://www.icebolethugroup.co.za/funeral-cover/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Visit icebolethu <span class="button__bg"></span>
                    </a>
                  </mc-button>
                </li>
              </ul>
            </nav>

            <nav
              class="site-footer__right"
              aria-label="Additional page options"
            >
              <ul class="footer-nav hide-s" id="swup-footer-nav">
                <li class="footer-nav__item"></li>

                

                <li class="footer-nav__item">
                  <a class="button button--text" href="../contact/index.htm">
                    Contact
                  </a>
                </li>
                

                <li class="footer-nav__item">
                  <share-links class="share-links">
                    <p class="share-links__toggle button button--text">
                      <span class="audio-only">Share navigation</span>

                      <svg
                        class="icon icon--share"
                        width="15"
                        height="17"
                        viewbox="0 0 15 17"
                        xmlns="http://www.w3.org/2000/svg"
                        focusable="false"
                      >
                        <g fill-rule="evenodd">
                          <circle cx="12.5" cy="2.5" r="2.5"></circle>
                          <circle cx="12.5" cy="14.5" r="2.5"></circle>
                          <circle cx="2.5" cy="8.5" r="2.5"></circle>
                          <path d="M12.75 1.63l.53.85L2.25 9.37l-.53-.85z"></path>
                          <path d="M2 8.85L2.53 8l11.03 6.89-.53.85z"></path>
                        </g>
                      </svg>
                    </p>

                    <div class="share-links__container">
                      <ul class="share-links__list">
                        <li class="share-links__item">
                          <share-link>
                            <a
                              class="share-links__link"
                              href="mailto:?subject=Marie+Curie+Memory+Cloud&body=www.memorycloud.org.uk"
                            >
                              <span class="audio-only">Share via email</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewbox="0 0 20 14"
                                width="20"
                                height="14"
                                class="icon icon--email"
                                focusable="false"
                              >
                                <path d="M.6 0h18.8c.3 0 .6.3.6.6v12.8c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0zm10.7 6.5l6.4-5.4H2.2l6.4 5.4L10 7.7l1.3-1.2zM15.1 5l-2.4 2 6.1 5.1V1.9L15.1 5zM7.3 7L1.2 1.9v10.3L7.3 7zm7 2.9l-2.6-2.2-1.3 1.1-2.1-1-6 5h15.5l-3.5-2.9z"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li class="share-links__item">
                          <share-link>
                            <a
                              class="share-links__link"
                              href="../login.php?u=www.memorycloud.org.uk"
                            >
                              <span class="audio-only">Share via facebook</span>

                              <svg
                                width="8"
                                height="16"
                                viewbox="0 0 8 16"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon--facebook"
                                focusable="false"
                              >
                                <path
                                  d="M5.193 16V8.702H7.55l.353-2.845H5.193V4.041c0-.823.22-1.384 1.357-1.384L8 2.656V.112A18.694 18.694 0 0 0 5.887 0C3.796 0 2.365 1.325 2.365 3.76v2.097H0v2.845h2.365V16h2.828z"
                                  fill-rule="nonzero"
                                ></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li class="share-links__item">
                          <share-link>
                            <a
                              class="share-links__link"
                              href="https://twitter.com/intent/tweet/?text=Marie+Curie+Memory+Cloud&url=www.memorycloud.org.uk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span class="audio-only">Share via twitter</span>

                              <svg
                                width="18"
                                height="14"
                                viewbox="0 0 18 14"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon--twitter"
                                focusable="false"
                              >
                                <path
                                  d="M5.55 13.944c6.66 0 10.301-5.364 10.301-10.016 0-.152-.003-.304-.01-.455a7.255 7.255 0 0 0 1.806-1.822 7.39 7.39 0 0 1-2.08.554A3.552 3.552 0 0 0 17.16.258c-.7.403-1.474.696-2.299.854A3.666 3.666 0 0 0 12.218 0c-2 0-3.621 1.576-3.621 3.52 0 .276.032.545.094.802A10.38 10.38 0 0 1 1.228.645a3.434 3.434 0 0 0-.49 1.769c0 1.221.64 2.3 1.611 2.93a3.672 3.672 0 0 1-1.64-.44v.044c0 1.705 1.248 3.129 2.904 3.451a3.729 3.729 0 0 1-1.635.06c.461 1.4 1.798 2.417 3.382 2.445a7.398 7.398 0 0 1-4.496 1.507c-.292 0-.58-.016-.864-.049a10.463 10.463 0 0 0 5.55 1.582"
                                  fill-rule="nonzero"
                                ></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li class="share-links__item">
                          <share-link>
                            <a
                              class="share-links__link"
                              href="https://api.whatsapp.com/send?text=www.memorycloud.org.uk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span class="audio-only">Share via WhatsApp</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewbox="0 0 24 24"
                                class="icon icon--whatsapp"
                                focusable="false"
                              >
                                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>
                      </ul>
                    </div>
                  </share-links>
                </li>
              </ul>

              <div class="site-footer__audio-toggle">
                <button class="audio-toggle">
                  <span class="audio-only">Toggle sounds</span>
                  <span class="audio-toggle__circle">
                    <svg
                      class="audio-toggle__svg"
                      width="14"
                      height="15"
                      viewbox="0 0 14 15"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <g class="audio-toggle__bar">
                        <rect x="9" y="0" width="2" height="15"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="12" y="12" width="2" height="3"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="3" y="9" width="2" height="6"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <rect x="0" y="3" width="2" height="12"></rect>
                      </g>

                      <g class="audio-toggle__bar">
                        <polygon points="6 6 8 6 8 15 6 15"></polygon>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </nav>
          </footer>
        </div>
      </smooth-scroll>

      <div class="page-border hide-s">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <style>{`
        .file-upload-container {
          display: flex;
          justify-content: space-between;
        }

        .file-upload {
          flex: 1;
          margin-right: 10px;
        }

        .input {
          max-width: 190px;
          display: none;
        }

        .labelFile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 190px;
          border: 2px dashed #ccc;
          align-items: center;
          text-align: center;
          padding: 5px;
          color: #404040;
          cursor: pointer;
        }

        .image-preview img {
          max-width: 100px;
          max-height: 100px;
          margin-top: 10px;
        }

        .additional-image-previews img {
          max-width: 100px;
          max-height: 100px;
          margin-right: 5px;
        }

          /* Media query for mobile view */
        @media (max-width: 768px) {
          .file-upload-container {
            flex-direction: column;
          }

          .file-upload {
            margin-right: 0;
            margin-bottom: 20px; /* Add some space between stacked file uploads */
          }
        }
      `}</style>
      <style>{`/* From Uiverse.io by escannord */ 
        .input {
          max-width: 190px;
          display: none;
        }
        
        .labelFile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 250px;
          height: 190px;
          border: 2px dashed #ccc;
          align-items: center;
          text-align: center;
          padding: 5px;
          color: #404040;
          cursor: pointer;
        }
        `}</style>
    </body>
  );
}

export default Input;
