import React, { useState } from 'react';
import './Home.css';
import paddles from '../assets/images/petals/petal-1-1.png';
import paddles1 from '../assets/images/petals/petal-1.png';
import logo from '../components/icebolethu.png';
import paddles2 from '../assets/images/petals/petal-2.png';
import paddles22 from '../assets/images/petals/petal-2-1.png';
import paddles3 from '../assets/images/petals/petal-3.png';
import paddles33 from '../assets/images/petals/petal-3-1.png';
import paddles4 from '../assets/images/petals/petal-4.png';
import paddles44 from '../assets/images/petals/petal-4-1.png';
import paddles5 from '../assets/images/petals/petal-5.png';
import paddles55 from '../assets/images/petals/petal-5-1.png';
import paddles6 from '../assets/images/petals/petal-6-1.png';
import paddles66 from '../assets/images/petals/petal-6.png';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="site-header">
        <div className="site-header__mob-top">
          <div className="site-header__logo">
            <skip-links id="swup-skip-links">
              <ul className="skip-links">
                <li>
                  <mc-button>
                    <a className="button skip-links__link" href="#swup-main">Skip to main content<span className="button__bg"></span></a>
                  </mc-button>
                </li>
              </ul>
            </skip-links>

            <a className="site-header__logo" href="/">
              <span className="audio-only">Marie Curie Memory Wall Home</span>
              <img width={150} src={logo} alt="Logo" />
            </a>
          </div>

          <button className="nav-toggle button-reset hide-no-js hide-l" onClick={toggleMenu}>
            <span className="nav-toggle__icon">
              <span className="nav-toggle__icon-dash"></span>
              <span className="nav-toggle__icon-dash"></span>
              <span className="nav-toggle__icon-dash"></span>
            </span>

            <span className="nav-toggle__text">
              <span className="audio-only">Toggle</span>
              <span className="nav-toggle__open">Menu</span>
              <span className="nav-toggle__close" aria-hidden="true">Close</span>
            </span>
          </button>
        </div>

        <div className={`site-header__mob-bottom ${menuOpen ? 'open' : ''}`}>
          <nav className="site-header__nav-container" id="swup-header-nav">
            <ul className="site-header__nav site-nav" aria-label="Page navigation">
              <li className="site-nav__item">
                <a  onClick={toggleMenu} className="site-nav__link button button--text" href="../index.htm">Explore</a>
              </li>

              <li className="site-nav__item">
                <a  onClick={toggleMenu} className="site-nav__link button button--text" href="#album">Album</a>
              </li>

              <li className="site-nav__item">
                <a  onClick={toggleMenu} className="site-nav__link button button--text" href="#memories">Memories</a>
              </li>

              <li className="site-nav__item">
                <a  onClick={toggleMenu} className="site-nav__link button button--text" href="#eulogy">Eulogy</a>
              </li>
            </ul>

            <div className="site-header__featured-cta">
              <a className="site-header__search-link hide-s" href="../../search/index.htm">
                <span className="audio-only">Search tributes</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                  <path stroke="currentColor" fill="none" d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"></path>
                </svg>
              </a>

              <mc-button  onClick={toggleMenu}>
                <a className="button" href="/input">Add a loved one<span className="button__bg"></span></a>
              </mc-button>
            </div>

            <div className="site-header__edit-cta hide-l">
              <mc-button  onClick={toggleMenu}>
                <a className="button" href="https://www.mariecurie.org.uk/donate?utm_source=memc&utm_medium=website&utm_campaign=MEMC&utm_content=link_" rel="noopener noreferrer" target="_blank">Donate <span className="button__bg"></span></a>
              </mc-button>
            </div>

            <ul className="site-header__nav site-nav site-nav--footer hide-l">
              <li className="site-nav__item">
                <a  onClick={toggleMenu} className="footer-nav__link button button--text color-sandstone" href="../../contact/index.htm">Contact</a>
              </li>
              <li className="site-nav__item">
                <a className="footer-nav__link button button--text color-sandstone" href="https://www.mariecurie.org.uk/help/support/telephone-bereavement-support?utm_source=memc&utm_medium=website&utm_campaign=MEMC&utm_content=link_" rel="noopener noreferrer" target="_blank">Lomus support</a>
              </li>

              <li className="site-nav__item color-sandstone">
                <button className="button button--text" type="button">Cookie Settings</button>
              </li>
            </ul>

            <div className="site-header__additional-nav hide-l">
              <ul className="footer-nav" aria-label="Additional page options">
                <li className="footer-nav__item">
                  <share-links className="share-links">
                    <p className="share-links__toggle button button--text">
                      <span className="audio-only">Share navigation</span>
                      <svg className="icon icon--share" width="15" height="17" viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg" focusable="false">
                        <g fillRule="evenodd">
                          <circle cx="12.5" cy="2.5" r="2.5"></circle>
                          <circle cx="12.5" cy="14.5" r="2.5"></circle>
                          <circle cx="2.5" cy="8.5" r="2.5"></circle>
                          <path d="M12.75 1.63l.53.85L2.25 9.37l-.53-.85z"></path>
                          <path d="M2 8.85L2.53 8l11.03 6.89-.53.85z"></path>
                        </g>
                      </svg>
                    </p>

                    <div className="share-links__container">
                      <ul className="share-links__list">
                        <li className="share-links__item">
                          <share-link>
                            <a className="share-links__link" href="mailto:?subject=Marie+Curie+Memory+Cloud&body=www.memorycloud.org.uk">
                              <span className="audio-only">Share via email</span>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14" width="20" height="14" className="icon icon--email" focusable="false">
                                <path d="M.6 0h18.8c.3 0 .6.3.6.6v12.8c0 .3-.3.6-.6.6H.6c-.3 0-.6-.3-.6-.6V.6C0 .3.3 0 .6 0zm10.7 6.5l6.4-5.4H2.2l6.4 5.4L10 7.7l1.3-1.2zM15.1 5l-2.4 2 6.1 5.1V1.9L15.1 5zM7.3 7L1.2 1.9v10.3L7.3 7zm7 2.9l-2.6-2.2-1.3 1.1-2.1-1-6 5h15.5l-3.5-2.9z"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li className="share-links__item">
                          <share-link>
                            <a className="share-links__link" href="../../login.php?u=www.memorycloud.org.uk">
                              <span className="audio-only">Share via facebook</span>
                              <svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" className="icon icon--facebook" focusable="false">
                                <path d="M5.193 16V8.702H7.55l.353-2.845H5.193V4.041c0-.823.22-1.384 1.357-1.384L8 2.656V.112A18.694 18.694 0 0 0 5.887 0C3.796 0 2.365 1.325 2.365 3.76v2.097H0v2.845h2.365V16h2.828z" fillRule="nonzero"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li className="share-links__item">
                          <share-link>
                            <a className="share-links__link" href="https://twitter.com/intent/tweet/?text=Marie+Curie+Memory+Cloud&url=www.memorycloud.org.uk" target="_blank" rel="noopener noreferrer">
                              <span className="audio-only">Share via twitter</span>
                              <svg width="18" height="14" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" className="icon icon--twitter" focusable="false">
                                <path d="M5.55 13.944c6.66 0 10.301-5.364 10.301-10.016 0-.152-.003-.304-.01-.455a7.255 7.255 0 0 0 1.806-1.822 7.39 7.39 0 0 1-2.08.554A3.552 3.552 0 0 0 17.16.258c-.7.403-1.474.696-2.299.854A3.666 3.666 0 0 0 12.218 0c-2 0-3.621 1.576-3.621 3.52 0 .276.032.545.094.802A10.38 10.38 0 0 1 1.228.645a3.434 3.434 0 0 0-.49 1.769c0 1.221.64 2.3 1.611 2.93a3.672 3.672 0 0 1-1.64-.44v.044c0 1.705 1.248 3.129 2.904 3.451a3.729 3.729 0 0 1-1.635.06c.461 1.4 1.798 2.417 3.382 2.445a7.398 7.398 0 0 1-4.496 1.507c-.292 0-.58-.016-.864-.049a10.463 10.463 0 0 0 5.55 1.582" fillRule="nonzero"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>

                        <li className="share-links__item">
                          <share-link>
                            <a className="share-links__link" href="https://api.whatsapp.com/send?text=www.memorycloud.org.uk" target="_blank" rel="noopener noreferrer">
                              <span className="audio-only">Share via WhatsApp</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="icon icon--whatsapp" focusable="false">
                                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                              </svg>
                            </a>
                          </share-link>
                        </li>
                      </ul>
                    </div>
                  </share-links>
                </li>
              </ul>
            </div>
          </nav>

          <div className="site-header__search hide-l">
            <div className="search-form">
              <form action="/explore/barry-chuckle/" autoComplete="off" encType="multipart/form-data" method="post">
                <input name="__RequestVerificationToken" type="hidden" value="lxlOY5hPsw5mVwQCsthQotmjnfJfz3QigxgIAzCFRZZbE-WVbOROiNq13WcgiMwI-2O6SKfFZ71o7_EQArLuFei_iIK9u76EKlKZ835qq7Q1" />
                <div className="form__validation-summary"></div>
                <form-field className="form-field form-field--text form-field--search">
                  <label className="form-field__label" htmlFor="Name">
                    <span className="form-field__label-text">
                      <span className="audio-only-s">Search Memory Cloud</span>
                      <span className="hide-l" aria-hidden="true">Search...</span>
                    </span>
                  </label>
                  <input className="form-field__input" id="Name" name="Name" type="search" value="" placeholder="Search..." />
                </form-field>
                <button className="button-reset search-form__submit" type="submit">
                  <span className="audio-only">Submit</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                    <path stroke="currentColor" fill="none" d="M15 15l5 5M17 9A8 8 0 111 9a8 8 0 0116 0z"></path>
                  </svg>
                </button>
                <span className="form__working-message">Searching</span>
                <input name='ufprt' type='hidden' value='49EB561701802837E1D4528AF82F4BAD67B1DF365B55C20D956DC84CBA5375DE48C2DFE22C8D95F2E30A9D279B53C0D1A2753B6BCCCA94ACBC6C6DDECE36EA62211D16C1D17046B756B915F5E624104C19D5D3B5896F9FB36DF73529C390CAE9088DB2844CA23D211CBBA2584E8B44DBB9C374382E4DFAC5A393E6279559E50946667F12DF820E0D319835DEA1E9C025' />
              </form>
            </div>
          </div>
        </div>
      </header>
      <div className="home-container">
        <div className="content">
          <h1>Stories to Remember</h1>
          <p>
          The Icebolethu E-Program is a digital platform designed to enhance client access to funeral and related services. It aims to provide a seamless experience for clients by offering online tools, support, and resources for funeral arrangements, insurance, and memorial products.          </p>
          <mc-button class="petals-hero__button">
				<a  href="/input" class="button">
					Add a loved one <span class="button__bg"></span>
				<span style={{ position: 'absolute', display: 'block',
           width: '108px', height: '108px',borderRadius: '50%', background: 'rgb(255, 255, 255)', opacity: '0.4', transform: 'translate(-50%, -50%) scale(0)', transformOrigin: 'center center', transition: 'transform 0.25s ease-in-out', pointerEvents: 'none', zindex: '-1'}}>
          </span></a>
			</mc-button>
        </div>
        <div className="petals-container">
          {/* Add individual petals here */}
          <img src={paddles} className="petal petal-1" alt="Petal" />
          <img src={paddles1} className="petal petal-2" alt="Petal" />
          <img src={paddles22} className="petal petal-3" alt="Petal" />
          <img src={paddles2} className="petal petal-4" alt="Petal" />
          <img src={paddles3} className="petal petal-5" alt="Petal" />
          <img src={paddles33} className="petal petal-6" alt="Petal" />
          <img src={paddles4} className="petal petal-7" alt="Petal" />
          <img src={paddles44} className="petal petal-8" alt="Petal" />
          <img src={paddles5} className="petal petal-9" alt="Petal" />
          <img src={paddles55} className="petal petal-10" alt="Petal" />
          <img src={paddles6} className="petal petal-11" alt="Petal" />
          <img src={paddles66} className="petal petal-12" alt="Petal" />
          {/* Add more petals as needed */}
        </div>
      </div>
    </>
  );  
}

export default Home;
